import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "\n      flex flex-row\n      bg-gray-lp-200\n      w-200px\n      m-5\n      text-center\n      rounded-md\n      border border-gray-lp-400\n    " }
const _hoisted_2 = {
  key: 0,
  class: "text-24px text-center w-48 "
}
const _hoisted_3 = { class: "p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icons = _resolveComponent("Icons")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.captcha && _ctx.captcha.length)
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.captcha, (c, i) => {
            return (_openBlock(), _createBlock("div", {
              key: i,
              style: {
          fontSize: _ctx.getFontSize() + 'px',
          fontWeight: 800,
          transform: 'rotate(' + _ctx.getRotationAngle() + 'deg)'
        },
              class: "inline-block tracking-wides"
            }, _toDisplayString(c), 5))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_Icons, {
        name: "refresh",
        color: "white",
        class: "cursor-pointer bg-red-lp-300 rounded-md",
        onClick: _ctx.createCaptcha
      }, null, 8, ["onClick"])
    ])
  ]))
}