
import { prop, Vue, Options } from "vue-class-component";

class Props {
  modelValue = prop<string>({
    required: true
  });
}

@Options({
  emits: ["resetCapctha"]
})
export default class Capctha extends Vue.with(Props) {
  created() {
    this.createCaptcha();
  }

  captchaLength = 5;
  captcha: any = [];
  createCaptcha() {
    let tempCaptcha = "";
    for (let i = 0; i < this.captchaLength; i++) {
      tempCaptcha += this.getRandomCharacter();
    }
    this.captcha = tempCaptcha.split("");
    this.$emit("resetCaptcha", "");
    this.$emit("update:modelValue", this.captcha.join(""));
  }

  getRandomCharacter() {
    const symbols = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const randomNumber = Math.floor(Math.random() * 36);
    return symbols[randomNumber];
  }

  getFontSize() {
    const fontVariations = [14, 20, 30, 36, 40];
    return fontVariations[Math.floor(Math.random() * 5)];
  }

  getRotationAngle() {
    const rotationVariations = [5, 10, 20, 25, -5, -10, -20, -25];
    return rotationVariations[Math.floor(Math.random() * 8)];
  }
}
